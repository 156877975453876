<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="月份:" style="width: 330px">
        <el-date-picker
          v-model="searcForm.date"
          type="month"
          placeholder="选择月"
          value-format="yyyy-MM"
          @change="dateChange"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="商品:"
        prop="productId"
        style="width: 330px"
        class="F"
      >
        <el-input
          v-if="checkProductName === ''"
          placeholder="请选择商品"
          clearable
          v-model="checkProductName"
          @focus.stop="openProduct"
          @clear="clearProduct"
        ></el-input>
        <el-tooltip
          v-else
          class="item"
          effect="dark"
          :content="checkProductName"
          placement="top-start"
        >
          <el-input
            placeholder="请选择商品"
            clearable
            v-model="checkProductName"
            @focus.stop="openProduct"
            @clear="clearProduct"
          ></el-input>
        </el-tooltip>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" size="mini" @click="searchForm"
            >查询</el-button
          >
          <el-button type="primary" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border="">
      <el-table-column prop="code" label="商品编号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="商品名称" align="center">
      </el-table-column>
      <el-table-column prop="business" label="业务类型" align="center">
        <template slot-scope="scope">
          {{
            scope.row.business == 0
              ? "期初"
              : scope.row.business == 1
              ? "采购入库"
              : scope.row.business == 2
              ? "采购退货"
              : scope.row.business == 3
              ? "销售出库"
              : scope.row.business == 4
              ? "销售退货"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="entryDate" label="业务日期" align="center">
        <template slot-scope="scope">
          {{ scope.row.entryDate ? scope.row.entryDate : "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="no" label="业务单号" align="center">
      </el-table-column>
      <el-table-column prop="wmsName" label="仓库" align="center">
      </el-table-column>
      <!-- <el-table-column label="期初" align="center">
        <el-table-column prop="num" label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 0 ? scope.row.business : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="平均成本" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 0 ? scope.row.price.toFixed(2) : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.business == 0 ? scope.row.totalPrice.toFixed(2) : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column> -->
      <el-table-column label="入库" align="center">
        <el-table-column prop="num" label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 1 ? scope.row.business : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="平均成本" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 1 ? scope.row.price.toFixed(2) : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.business == 1 ? scope.row.totalPrice.toFixed(2) : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column>
      <!-- <el-table-column label="采购退货" align="center">
        <el-table-column prop="num" label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 2 ? scope.row.business : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="平均成本" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 2 ? scope.row.price.toFixed(2) : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.business == 2 ? scope.row.totalPrice.toFixed(2) : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column> -->
      <el-table-column label="出库" align="center">
        <el-table-column prop="num" label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 3 ? scope.row.business : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="平均成本" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 3 ? scope.row.price.toFixed(2) : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.business == 3 ? scope.row.totalPrice.toFixed(2) : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column>
      <!-- <el-table-column label="销售退货" align="center">
        <el-table-column prop="num" label="数量" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 4 ? scope.row.business : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="平均成本" align="center">
          <template slot-scope="scope">
            {{ scope.row.business == 4 ? scope.row.price.toFixed(2) : "--" }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.business == 4 ? scope.row.totalPrice.toFixed(2) : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column> -->

      <el-table-column label="结存" align="center">
        <el-table-column prop="productNum" label="数量" align="center">
        </el-table-column>
        <el-table-column prop="productPrice" label="平均成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.productTotalPrice
                ? scope.row.productTotalPrice.toFixed(2)
                : "--"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="productTotalPrice" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.productTotalPrice
                ? scope.row.productTotalPrice.toFixed(2)
                : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content: space-between;">
      <div></div>
      <el-pagination
        class="pagination"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="searcForm.pageNum"
        :page-size="searcForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div></div>
    </div>
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods
          :isStatement="true"
          :checkArr="checkProductArr"
          @close="Visible = false"
          @SelectGoods="changeProductId"
        />
      </div>
    </miaDialog>
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>

<script>
import SelectGoods from "@/components/pustorageTable/SelectGoods.vue";
import { getProductList } from "@/api/procurementSummary";
import { getSummaryDetail } from "@/api/inventoryTable";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    SelectGoods,
    OpenAccount,
  },
  data() {
    return {
      info: {
        show: false,
      },
      Visible: false,
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        date: "",
        productIds: "",
      }, //搜索条件
      productList: [], //商品列表
      storehouse: [], //供应商
      tableData: [], //导出组装的数据
      checkProductArr: [],
      checkProductName: "",
      total: 0,
      summaryLists: [],
      changeFlag:false,
    };
  },
  created() {
    this.getConfigBills();
    this.getList();
    this.getProductList();
  },
  methods: {
     dateChange() {
      this.changeFlag = true;
    },
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
        }
      }
    },
    getValue(key, arr, val) {
      let reVal = "";
      arr.forEach((item) => {
        if (val == item.code) {
          reVal = item[key];
        }
      });
      return reVal;
    },
    //处理数据
    getVales(arr) {
      let arr2 = [];
      let arr3 = []; //单独统计仓库
      arr.forEach((a) => {
        a.summaryList.forEach((b) => {
          arr2.push({ ...b, code: a.code }); //放入商品唯一值
        });
      }); // 去重仓库,统计仓库个数
      let newobj = {};
      arr3 = arr2.reduce((preVal, curVal) => {
        newobj[curVal.wmsId]
          ? ""
          : (newobj[curVal.wmsId] = preVal.push(curVal));
        return preVal;
      }, []); // 根据去重后仓库进行数据合并

      let arr4 = []; //最终数据
      arr3.forEach((v) => {
        let goodsArr = []; //同仓库下的商品集合
        arr2.forEach((b) => {
          if (v.wmsId == b.wmsId) {
            goodsArr.push(b);
          }
        });
        arr4.push({ goodsArr: goodsArr, ...v });
      });
      this.summaryLists = arr4;
      // console.log(arr4);

      // this.tableData.forEach((item) => {
      //   item.wmsList.forEach((items, indexs) => {
      //     let totalNum = 0;
      //     let totalPrices = 0;
      //     if (items.num > 0) {
      //       if (item.id === items.productId) {
      //         totalNum += items.num;
      //         totalPrices += items.totalPrice * items.num;
      //         console.log(items.totalPrice, items.num);
      //         if (indexs != 0) {
      //           totalNum += item.totalNum;
      //           totalPrices += item.totalPrices;
      //         }

      //         item.totalNum = totalNum;
      //         item.totalPrices = totalPrices;
      //       }
      //     }
      //   });
      // });
      // console.log(this.tableData);
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getList();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getList();
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      //   var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      var defaultDate = `${year}-${month}`;
      //   this.searcForm.startDate = `${year}-${month}-01`;
      //   this.searcForm.endDate = `${year}-${month}-${date}`;
      this.searcForm.date = defaultDate;
      // console.log(defaultDate);
    },
    clearProduct() {
      this.checkProductArr = [];
    },
    checkProductArrs(val) {
      this.checkProductName =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.name;
              })
              .join("、");
      this.searcForm.productIds =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.id;
              })
              .join(",");
    },
    openProduct() {
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.SelectGoods.init("选择商品");
      });
    },
    changeProductId(list) {
      this.Visible = false;
      this.checkProductArr = list;
      this.checkProductArrs(list);
    },
    //跳转库存分布
    deInventoryDistribution() {
      this.$router.push({
        path: "/inventoryTable/detailsList/index",
      });
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        const multiHeader = [
          [
            "商品编号",
            "商品名称",
            "业务类型",
            "业务日期",
            "业务单号",
            "仓库",
            "入库",
            "",
            "",
            "出库",
            "",
            "",
            "结存",
            "",
            "",
          ],
        ]; // 前两行的表头数据，二维数组，不够的用空白补全
        const header = [
          "",
          "",
          "",
          "",
          "",
          "",
          "数量",
          "平均成本",
          "总成本",
          "数量",
          "平均成本",
          "总成本",
          "数量",
          "平均成本",
          "总成本",
        ]; // 最后一行的表头数据
        const filterVal = [
          "code",
          "name",
          "businesss",
          "entryDate",
          "no",
          "wmsName",
          "rukuNum",
          "rukuPrice",
          "rukuPrice",
          "xiaoshouNum",
          "xiaoshouPrice",
          "xiaoshouPrice",
          "productNum",
          "productPrice",
          "productTotalPrice",
        ];
        const merges = [
          "A1:A2",
          "B1:B2",
          "C1:C2",
          "D1:D2",
          "E1:E2",
          "F1:F2",
          "G1:I1",
          "J1:L1",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        // const list = ;
        this.tableDatas = JSON.parse(JSON.stringify(this.tableData));
        this.tableDatas.forEach((item) => {
          if (item.business == 0) {
            item.businesss = "期初";
            item.qichuNum = item.num;
            item.qichuPrice = item.price;
            item.qichuTotalprice = item.totalPrice;
            item.rukuNum = 0;
            item.rukuPrice = 0;
            item.rukuTotalprice = 0;
            item.caigoutuihuoNum = 0;
            item.caigoutuihuoPrice = 0;
            item.caigoutuihuoTotalprice = 0;
            item.xiaoshouNum = 0;
            item.xiaoshouPrice = 0;
            item.xiaoshouTotalprice = 0;
            item.xiaoshoutuihuoNum = 0;
            item.xiaoshoutuihuoPrice = 0;
            item.xiaoshoutuihuoTotalprice = 0;
          } else if (item.business == 1) {
            item.businesss = "采购入库";
            item.qichuNum = 0;
            item.qichuPrice = 0;
            item.qichuTotalprice = 0;
            item.rukuNum = item.num;
            item.rukuPrice = item.price;
            item.rukuTotalprice = item.totalPrice;
            item.caigoutuihuoNum = 0;
            item.caigoutuihuoPrice = 0;
            item.caigoutuihuoTotalprice = 0;
            item.xiaoshouNum = 0;
            item.xiaoshouPrice = 0;
            item.xiaoshouTotalprice = 0;
            item.xiaoshoutuihuoNum = 0;
            item.xiaoshoutuihuoPrice = 0;
            item.xiaoshoutuihuoTotalprice = 0;
          } else if (item.business == 2) {
            item.businesss = "采购退货";
            item.qichuNum = 0;
            item.qichuPrice = 0;
            item.qichuTotalprice = 0;
            item.rukuNum = 0;
            item.rukuPrice = 0;
            item.rukuTotalprice = 0;
            item.caigoutuihuoNum = item.num;
            item.caigoutuihuoPrice = item.price;
            item.caigoutuihuoTotalprice = item.totalPrice;
            item.xiaoshouNum = 0;
            item.xiaoshouPrice = 0;
            item.xiaoshouTotalprice = 0;
            item.xiaoshoutuihuoNum = 0;
            item.xiaoshoutuihuoPrice = 0;
            item.xiaoshoutuihuoTotalprice = 0;
          } else if (item.business == 3) {
            item.businesss = "销售出库";
            item.qichuNum = 0;
            item.qichuPrice = 0;
            item.qichuTotalprice = 0;
            item.rukuNum = 0;
            item.rukuPrice = 0;
            item.rukuTotalprice = 0;
            item.caigoutuihuoNum = 0;
            item.caigoutuihuoPrice = 0;
            item.caigoutuihuoTotalprice = 0;
            item.xiaoshouNum = item.num;
            item.xiaoshouPrice = item.price;
            item.xiaoshouTotalprice = item.totalPrice;
            item.xiaoshoutuihuoNum = 0;
            item.xiaoshoutuihuoPrice = 0;
            item.xiaoshoutuihuoTotalprice = 0;
          } else if (item.business == 4) {
            item.businesss = "销售退货";
            item.qichuNum = 0;
            item.qichuPrice = 0;
            item.qichuTotalprice = 0;
            item.rukuNum = 0;
            item.rukuPrice = 0;
            item.rukuTotalprice = 0;
            item.caigoutuihuoNum = 0;
            item.caigoutuihuoPrice = 0;
            item.caigoutuihuoTotalprice = 0;
            item.xiaoshouNum = 0;
            item.xiaoshouPrice = 0;
            item.xiaoshouTotalprice = 0;
            item.xiaoshoutuihuoNum = item.num;
            item.xiaoshoutuihuoPrice = item.price;
            item.xiaoshoutuihuoTotalprice = item.totalPrice;
          } else {
          }
        });
        // console.log(filterVal,this.tableDatas);
        const data = this.formatJson(filterVal, this.tableDatas);
        // console.log(data);
        // console.log(filterVal,multiHeader,header);
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename: "收发存明细表",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    async getList() {
       if (!this.changeFlag) {
        this.getDate();
      }
      let params = {
        pageNum: this.searcForm.pageNum,
        pageSize: this.searcForm.pageSize,
        date: this.searcForm.date,
        productIds: this.searcForm.productIds || this.$route.query.id,
      };
      let res = await getSummaryDetail(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
        // this.getVales(this.tableData);
      }
    },
    //查询按钮
    searchForm() {
      this.getList();
    },
    //获取商品列表
    async getProductList() {
      let res = await getProductList();
      if (res.code == 1) {
        this.productList = res.data.records;
      }
    },
  },
};
</script>

<style scoped>
.F {
  display: flex;
  flex-wrap: wrap;
}
.export-btn {
  width: 60px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #409eff;
  text-align: center;
  color: #409eff;
  font-size: 12px !important;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.view-wrap >>> th .cell {
  font-weight: bold !important;
  color: #606266 !important;
}
.view-wrap >>> .el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.view-wrap >>> .el-select__tags {
  overflow: hidden !important;
}
.view-wrap >>> .el-table__footer-wrapper tbody td {
  color: #000;
  font-weight: bold;
}
</style>